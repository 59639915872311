import React from "react";

import "./Growth.css";

export const Growth = () => {
  return (
    <div className="Growth">
      <div className="container-growth">
        <div className="primary-growth core">
          <div className="split-container pad-right-growth">
            <h2 className="subheading-growth">A PLACE FOR POWERFUL GROWTH</h2>
            <p className="body-growth">
              The Capital One Impact Initiative fuels our investments here at the Innovation Center. Focused on advancing socioeconomic mobility to catalyze economic growth and close gaps in equity and opportunity, Capital One recognizes that when small businesses do well, local economies succeed and communities are strengthened.
            </p>
          </div>
        </div>
        <div className="secondary-growth">
          <div className="split-container pad-left-growth growth-bg">

          </div>
        </div>
      </div>
    </div>
  );
};
