import React from "react";

import "./Overview.css";

export const Overview = () => {
  return (
    <div className="Overview">
      <div className="container-overview" id="overview">
        <div className="wrapper-overview core">
          <h2 className="subheading-overview">OVERVIEW</h2>
          <p className="body-copy overview">
            The place where inspiration, community, and innovation intersect.
            Imagined as an all-in-one experience design lab, conference venue
            and event space, the Michael Wassmer Innovation Center is a collaborative
            workspace for multi-disciplinary experts, community organizations
            and entrepreneurs to share ideas, learn from each other and discover
            new ways to achieve their individual and collective mission(s).
          </p>
        </div>
      </div>
    </div>
  );
};
