import React from "react";

import "./Space.css";
import mobileCircle from "../../assets/img/circle-image-mobile.png";

export const Space = () => {
  return (
    <div className="Space">
      <div className="container-space" id="space">
        <div className="primary-space">
          <div className="split-container pad-right-space space-bg">
            
          </div>
        </div>
        <div className="secondary-space core">
          <div className="split-container pad-left-space">
            <div className="primary-space">
              <center>
              <img src={ mobileCircle } alt=""  className="mobile-only" />
              </center>
            </div>
            <h2 className="subheading-space">A SPACE AS INSPIRED AS ITS PEOPLE</h2>
            <p className="body-space">
              Imagined as an all-in-one experience design lab, 
              conference venue, and event space, 
              we invite multi-disciplinary experts to engage with community organizations 
              and entrepreneurs to share innovative new ways to help them meet their mission.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


