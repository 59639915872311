import React from "react";
import "./App.css";
import {
  Header,
  Introduction,
  Overview,
  Growth,
  Space,
  Startup,
  Footer,
} from "./components";

function App() {
  return (
    <div className="App">
      <Header className="App-header" />
      <Introduction className="App-introduction" />
      <Overview className="App-Overview" />
      <Growth className="App-Growth" />
      <Space className="App-Space" />
      <Startup className="App-Startup" />
      <Footer className="App-footer" />
    </div>
  );
}

export default App;
