import React from "react";
import "./Footer.css";
import logo from "../../assets/img/capital-one-logo.svg";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-color white center">
      <div className="divider"></div>
      <div className="primary-content core">
        <div className="inner">
          <p className="body-copy no-margin-bottom">1717 E. Cary Street</p>
          <p className="body-copy small-margin-bottom">Richmond, VA 23223</p>
          <p className="body-copy">
            <a target="_blank" rel="noopener noreferrer" href="mailto:1717@capitalone.com" className="emailLink">
              1717@capitalone.com
            </a>
          </p>
          <div className="capital-one-logo">
            <a
              href="https://www.capitalone.com/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="capital one logo"
            >
              <img src={logo} alt="" />
            </a>
          </div>
          <p className="body-copy small">© {currentYear} Capital One </p>
        </div>
      </div>
    </footer>
  );
};
