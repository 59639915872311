import React from "react";
import StartupLogo from '../../assets/img/startup-logo.png';
import MBLLogo from '../../assets/img/MBL.png';
import BridgingVALogo from '../../assets/img/bridge.jpeg';
import "./Startup.css";

export const Startup = () => {
  return (
    <div className="Startup">
      <div className="container-startup core">
        <div className="primary-startup">
          <div className="anchor" id="startupVirginia"></div>
            <h2 className="subheading-startup">STARTUP VIRGINIA</h2>
            <p className="body-startup">
              Startup Virginia is a nonprofit high-growth business incubator and entrepreneurial hub where innovators can learn, collaborate, and grow.
            </p>
            <p className="body-startup">
              The organization provides holistic programming and dynamic workspace to its member community, as well as free entrepreneurial education that is open to all.
            </p>
        </div>
        <div className="primary-startup">
          <div className="anchor" id="metroBusinessLeague"></div>
            <h2 className="subheading-startup">Metropolitan Business League</h2>
            <p className="body-startup">
            The Metropolitan Business League is a nonprofit, membership-based business association that promotes economic prosperity in Virginia through education, advocacy, access to resources, and building relationships for small, women-owned, and minority-owned businesses.
            </p>
        </div>
        <div className="primary-startup">
          <div className="anchor" id="bridgingVirginia"></div>
            <h2 className="subheading-startup">Bridging Virginia</h2>
            <p className="body-startup">
            Bridging Virginia is a nonprofit, community development loan fund that provides affordable capital and technical assistance for historically marginalized small businesses.
            </p>
        </div>
      </div>
      <div className="img-group-1">
        <div className="row">
          <div className="column">
            <center>
              <a href="https://www.startupvirginia.org/" target="_blank" rel="noopener noreferrer"><img className="start-logo" src={StartupLogo} alt="Startup Virginia Logo"></img></a>
            </center>
          </div>
          <div>
            <center>
              <a href="https://thembl.org/" target="_blank" rel="noopener noreferrer"><img className="mbl-logo" src={MBLLogo} alt="MBL Logo"></img></a>
            </center>
          </div>
          <div className="column">
            <center>
              <a href="https://www.bridgingvirginia.org/" target="_blank" rel="noopener noreferrer"><img className="bridge-logo" src={BridgingVALogo} alt="Bridging VA Logo"></img></a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};
