import React from "react";
import HeroLogo from '../../assets/img/header-hero-icon.svg';

import "./Introduction.css";

export const Introduction = () => {
  return (
    <div className="Introduction">
      <div className="container" id="introduction" role="main">
        <div className="primary-content hero core">
          <div className="wrapper valign">
            <img src={HeroLogo} alt="" className="hero-logo"/>
            <h2 className="heading-hero-title">Michael Wassmer Innovation Center</h2>
            <p className="body-copy-hero">
            @ 1717 East Cary
            </p>
            <h2 className="heading-hero">A Collaborative Workspace For Innovation</h2>
            <p className="body-copy-hero">
              Interested in taking a tour or attending an event?
            </p>
            <center>
            <a target="_blank" rel="noopener noreferrer" className="hero-button" href="mailto:1717@capitalone.com">EMAIL US</a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};
