import React from "react";
import "./HeaderLink.css";

export const HeaderLink = (props) => {
  return (
    <a href={props.link} className="headerLink" onClick={ () => props.onClick() }>
      {props.text}
    </a>
  );
};
