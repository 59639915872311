import React, { useState, useEffect } from "react";
import Headroom from "react-headroom";
import logo from "../../assets/img/capital-one-logo.svg";
import "./Header.css";
import { HeaderLink } from "./HeaderLink";

export const Header = () => {
  const [menuChecked, setMenuChecked] = useState(false);
  const [mobileScreenSize, setMobileScreenSize] = useState(window.innerWidth < 767);

  const links = ["Overview"]

  const toCamelCase = (text) => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => c.toUpperCase());
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  }

  const changeMenuChecked = () => {
    setMenuChecked(!menuChecked);
  }

  const checkScreenSize = () => {
    // 991px because 992px is set as min-width for non-mobile layout, making 991px the max-width for mobile layout
    setMobileScreenSize(window.matchMedia(`(max-width: 991px)`).matches);
  }

  useEffect(() => {
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <Headroom upTolerance={15} downTolerance={15}>
      <header className="header bg-color white">
        <div className="primary-content core">
          <div className="inner">
            <h1 className="capital-one-logo">
              <a href='/'>
                <img src={logo} alt="Capital One" style={{ width: "100px", height: "30px" }} />
              </a>
            </h1> 
            <nav>
              <input className="control" type="checkbox" id="toggleInput" checked={menuChecked} onChange={changeMenuChecked} />
              <label htmlFor="toggleInput" className="main-nav toggle-label" id="toggleLabel">
                <svg id="toggle-icon-svg" className="toggle-icon-svg" width="36px" preserveAspectRatio="xMinYMax meet" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                  <g id="toggle-icon" className="toggle-icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect id="line_1" fill="#000000" x="3" y="0" width="2" height="36"></rect>
                    <rect id="line_2" fill="#000000" x="15" y="0" width="2" height="36"></rect>
                    <rect id="line_3" fill="#000000" x="28" y="0" width="2" height="36"></rect>
                  </g>
                </svg>
              </label>
              <div className={`menu${ mobileScreenSize ? ' activated': ''}${ menuChecked ? ' active' : ''}`} data-module="main-menu">
                {links.map(linkText => (
                  <HeaderLink key={linkText} link={'#' + toCamelCase(linkText)} text={linkText} onClick={changeMenuChecked} />
                ))}
              </div>
            </nav>
          </div>
        </div>
      </header>
    </Headroom>
  );
};
